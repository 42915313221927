define(['app'], (app) => {

  const fastTrackBanner = () => {
    const component = {};
    const GA_EVENT_CATEGORY = 'Fast Track Engagement';
    const GA_EVENT_ACTION = 'Clicked Fast Pass | Header';

    const _init = (element) => {
      component.element = element;

      component.addEventListeners();
      return component;
    };

    const _addEventListeners = () => {
      component.element.addEventListener('click', component.bannerClicked);
    };

    const _bannerClicked = () => {
      app.publish('tracking/record', GA_EVENT_CATEGORY, GA_EVENT_ACTION);
    };

    component.init = _init;
    component.addEventListeners = _addEventListeners;
    component.bannerClicked = _bannerClicked;

    return component;
  };

  return fastTrackBanner;
});
